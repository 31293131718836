<template>
  <div
    :id="chartID"
    :style="{ height: chartHeight, width: chartHeight }"
    style="padding: 15px; box-sizing: border-box"
  ></div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件
import * as echarts from "echarts";
import { debounce } from "@/utils";
export default {
  props: {
    chartHeight: {
      default: "600px",
    },
    chartID: {
      default: "myChart1",
    },
  },
  data() {
    return {
      chart: null,
      chartWidth: "100%",
      autoResize: true,
      imageData: "",
    };
  },
  methods: {
    showChart(chartOption) {
      this.drawChart(chartOption);
    },
    drawChart(chartOption) {
      this.chart.setOption(chartOption);
    },
    getImageData()
    {
      return this.chart.getDataURL();
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener("resize", this.__resizeHanlder);
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName("sidebar-container")[0];
    sidebarElm.addEventListener("transitionend", this.__resizeHanlder);
    this.chart = echarts.init(document.getElementById(this.chartID));

    // 使用element-resize-detector组件
    var mainChart = document.getElementById(this.chartID);
    var elementResize = elementResizeDetectorMaker({
      strategy: "scroll", // <- 推荐监听滚动，提升性能
      callOnAdd: true, // 添加侦听器时是否应调用,默认true
    });
    elementResize.listenTo(mainChart, function (element) {
      echarts.init(mainChart).resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener("resize", this.__resizeHanlder);
    }
    const sidebarElm = document.getElementsByClassName("sidebar-container")[0];
    sidebarElm.removeEventListener("transitionend", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
<style></style>